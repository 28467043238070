






































import { FormValidations } from "@/mixins/form-validations";
import { Notification } from "@/models/notification.interface";
import Component, { mixins } from "vue-class-component";
import { Prop, PropSync, Watch } from "vue-property-decorator";
import { StyleCheck } from "@/mixins/style-check";

@Component({})
export default class EmailChecker extends mixins(FormValidations, StyleCheck) {
  @Prop({ default: false })
  disabled!: boolean;
  @Prop({ default: true })
  clearable!: boolean;
  @PropSync("email", { type: String }) syncedEmail!: string;
  @PropSync("invalid", { type: Boolean }) syncedInvalid!: boolean;
  loading = false;

  emailTimer = 0;
  @Watch("syncedEmail")
  async verifyEmail(value: string) {
    const emailRegex = new RegExp(
      `^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$`
    );

    if (this.syncedEmail && emailRegex.test(value as string)) {
      this.loading = true;
      clearTimeout(this.emailTimer);
      this.emailTimer = setTimeout(async () => {
        let exists = await this.$store
          .dispatch("authentication/userExists", {
            email: value,
            alias: "",
          })
          .catch(() => {
            const Error: Notification = {
              message: this.$tc("SignUp.fetchError.emailExists"),
              timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
              top: true,
              bottom: false,
              left: false,
              right: false,
              currentPath: this.$route.fullPath,
              error: true,
            };

            this.$store.dispatch("notifications/showNotification", Error);
          });
        this.syncedInvalid = exists;
        this.loading = false;
      }, 500);
    }
  }
}
